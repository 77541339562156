.app {
  text-align: center;

  /*background-image: url('/public/background.webp');*/
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-color: #1032CF;
  height: 100vh; /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */

  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}


/* Additional global styles */
body, #root {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}
