.login-cover{
    .ant-card-cover{
        padding: 24px;
    }

    button img{
        vertical-align: middle;
    }

}
